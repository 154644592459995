

$logo: "https://www.tanagra.dev/assets/images/tanagra-logo.png";

@import "./support/support";
@import "./color_schemes/light";
@import "./modules";
@import "./custom/custom";


